/// <reference lib="dom" />
import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import "@/styles/index.scss";

// markup
const HandoffPage: React.FC<DefaultPageProps> = ({ location, data }) => {
  const pageTitle = "Sonali Zaveri | Design Leader | Portfolio";

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <main>
        <div className="hero hero--home">
          <Header active="index" />
          <div className="content-container">
            <h1>
              Hello, my name is Sonali Zaveri.
              <small>
                I am a design leader living in the San Francisco Bay Area.
              </small>
            </h1>
            <p>
              I am currently working at ideas42 ventures where we are helping
              entrepreneurs build social impact businesses that alleviate the
              poverty tax. Learn more about our work by visiting our{" "}
              <a href="https://ideas42ventures.com" target="blank">
                website
              </a>
              .
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query HandoffPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <HandoffPage data={data} {...props} />}
  />
);
